<template>
  <div class="item-wrap" :style="{ '--margin-bottom': marginBottom }">
    <div class="title">{{ title }}</div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    marginBottom: {
      type: String,
      default: '12px'
    }
  }
}
</script>

<style lang="less" scoped>
.item-wrap {
  margin-bottom: var(--margin-bottom);
  .title {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 14px;
    background: linear-gradient(
      90deg,
      rgba(0, 127, 255, 0.08) 0%,
      rgba(0, 127, 255, 0) 100%
    );
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #202327;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 20px;
      background: #007fff;
    }
  }
}
</style>
