<template>
  <div class="item" :style="{'--margin-bottom': marginBottom}">
    <div class="label" :style="labelStyle">{{ label }}</div>
    <div class="value"><slot></slot></div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    marginBottom: {
      type: String,
      default: '12px'
    },
    labelStyle: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  display: flex;
  margin-bottom: var(--margin-bottom);

  .label {
    white-space: nowrap;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    line-height: 22px;
  }

  .value {
    font-size: 14px;
    color: #494e53;
    line-height: 22px;
  }
}
</style>
